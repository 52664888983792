import React from 'react';
import { VStack, Text, Center, Image, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

export const AdvantageCard = ({
  icon,
  title,
  text,
  link = null,
  iconContainerStyle,
  iconStyle,
  headStyle,
  textStyle,
  ...props
}) => {
  return (
    <VStack
      w="full"
      h={{ mobile: '280px', tablet: '260px', laptop: '440px' }}
      spacing="20px"
      justify="space-between"
      align="start"
      borderRadius={{ laptop: '48px', mobile: '24px' }}
      p={{ laptop: '12px', mobile: '8px' }}
      pb={{ mobile: '16px', laptop: '36px' }}
      boxSizing="border-box"
      {...props}
    >
      <Center
        boxSize={{ mobile: '36px', laptop: '72px' }}
        borderRadius="full"
        bgColor="bg.white"
        flexShrink={0}
        {...iconContainerStyle}
      >
        <Image
          src={icon}
          boxSize={{ mobile: '18px', laptop: '36px' }}
          objectFit="contain"
          {...iconStyle}
        />
      </Center>

      <VStack
        p={{ mobile: '0 8px', tablet: '0 4px 0 8px', laptop: '0 24px' }}
        spacing={{ laptop: '20px', mobile: '16px' }}
        boxSizing="border-box"
        align="start"
      >
        <Text
          as="h4"
          textStyle={{ mobile: 'header.h4', laptop: 'h4', desktop: 'header.h4' }}
          {...headStyle}
        >
          {title}
        </Text>
        <Text
          as="p"
          textStyle="h4"
          fontSize={{ mobile: '16px', laptop: '18px', desktop: '24px' }}
          color="text.darkGray"
          {...textStyle}
        >
          {text}
        </Text>
        {link && (
          <Link
            as={GatsbyLink}
            to={link}
            target="_blank"
            textStyle="button.secondary"
            textDecoration="underline"
            textUnderlineOffset="5px"
            _after={{ content: `'.'`, color: 'bg.accent' }}
          >
            Подробнее
          </Link>
        )}
      </VStack>
    </VStack>
  );
};

export default AdvantageCard;
