import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import React, { useRef } from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import ServiceCard from '~entities/DevCycleCard';
import BlockHeader from 'shared/BlockHeader';
import { goToForm } from 'shared/lib/goToForm';

import { devCycleData, devCycleCardsStyles } from 'constants/1C/devCycle';

export default function DevelopmentCycle() {
  const containerRef = useRef(null);

  useGSAP(
    () => {
      const mm = gsap.matchMedia();
      const gsapInit = context => {
        const { isLaptop, isTablet, isMobile } = context.conditions;

        const getOffsetValues = i => {
          if (isMobile) {
            return i === 1 ? i * 60 : i * 60 - (i - 1) * 20;
          }

          if (isTablet) {
            return i === 1 ? i * 90 : i * 90 - (i - 1) * 40;
          }

          if (isLaptop) {
            return i === 1 ? i * 100 : i * 100 - (i - 1) * 40;
          }

          return i === 1 ? i * 125 : i * 125 - (i - 1) * 65;
        };

        const cards = gsap.utils.toArray('.dev-cycle-card');
        cards.forEach((card, i) => {
          ScrollTrigger.create({
            trigger: card,
            pin: true,
            pinSpacing: false,
            scrub: 1,
            start: () => `top ${getOffsetValues(i + 1)}px`,
            end: () => `top ${getOffsetValues(cards.length)}px`,
            endTrigger: cards.at(-1),
            onLeave: () => gsap.set(card, { left: '0' }),
          });
        });
      };

      mm.add(
        {
          isDesktop: '(min-width: 992px)',
          isLaptop: '(min-width: 742px) and (max-width: 991px)',
          isTablet: '(min-width: 656px) and (max-width: 741px)',
          isMobile: '(max-width: 655px)',
        },
        context => gsapInit(context),
      );
    },
    { scope: containerRef.current },
  );

  return (
    <VStack ref={containerRef} w="full" align="start" spacing={{ mobile: '24px', tablet: '48px' }}>
      <BlockHeader maxW={{ tablet: '520px', laptop: '900px' }}>
        Решаем задачи любой сложности
      </BlockHeader>
      <VStack w="full" spacing={{ laptop: '40px', tablet: '24px', mobile: '20px' }}>
        <VStack w="full" spacing="20px">
          {devCycleData?.map((val, i) => (
            <ServiceCard
              key={val.title}
              className={'dev-cycle-card'}
              {...val}
              {...devCycleCardsStyles[i]}
            />
          ))}
        </VStack>
        <Button size="secondary" w="full" onClick={goToForm}>
          Оставить заявку
        </Button>
      </VStack>
    </VStack>
  );
}
