import React from 'react';
import { Box, Grid, VStack } from '@chakra-ui/react';

import ExpertiseCard from '~entities/ExpertiseCard';
import BlockHeader from 'shared/BlockHeader';
import Slider from 'shared/Slider';

import { configurationsData, configurationsCardsStyle } from 'constants/1C/configurations';

export default function configurations() {
  return (
    <VStack w="full" align="start" spacing={{ mobile: '32px', laptop: '48px' }}>
      <BlockHeader>Работаем с конфигурациями</BlockHeader>
      <Grid
        w="full"
        templateColumns={{ xxl: 'repeat(4,1fr)', tablet: 'repeat(2,1fr)' }}
        templateRows={{ laptop: 'auto', tablet: 'repeat(2, auto)' }}
        gridGap={{ laptop: '20px', sm: '16px', mobile: '12px' }}
        placeItems="center"
        hideBelow="tablet"
      >
        {configurationsData?.map((val, i) => (
          <ExpertiseCard key={val.title} {...val} {...configurationsCardsStyle} />
        ))}
      </Grid>
      <Box w="100%" overflow="hidden" hideFrom="tablet">
        <Slider
          slidesData={configurationsData}
          SlideComponent={ExpertiseCard}
          sameStyles={configurationsCardsStyle}
          mousewheel={false}
          slidesPerView="auto"
        />
      </Box>
    </VStack>
  );
}
