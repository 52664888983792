import confs from 'assets/1c/services/confs.svg';
import integrations from 'assets/1c/services/integrations.svg';
import modif from 'assets/1c/services/modif.svg';
import optimization from 'assets/1c/services/optimization.svg';
import change from 'assets/1c/services/change.svg';
import attraction from 'assets/1c/services/attraction.svg';

export const devCycleData = [
  {
    title: 'Разработка конфигураций 1С с нуля',
    text: 'Разрабатываем новые конфигурации в соответствии с бизнес-процессами компании, чтобы устранить пробелы в работе типовых конфигураций',
    image: confs,
  },
  {
    title: 'Подключение 1С к системам компании',
    text: 'Разрабатываем механизм, который позволяет объединить 1С с бизнес-приложениями и инфраструктурой компании',
    image: integrations,
  },
  {
    title: 'Улучшение типовых решений 1С',
    text: 'Добавляем необходимые функциональные модули к типовым продуктам 1С, чтобы оптимизировать бизнес-процессы',
    image: modif,
  },
  {
    title: 'Привлечение разработчиков 1С',
    text: 'Усиливаем вашу команду разработчиками, аналитиками, тестировщиками и другими специалистами ASTON',
    image: attraction,
  },
  {
    title: 'Тестирование и оптимизация работы 1С',
    text: 'Определяем и исправляем ошибки в работе системы, обновляем базу и проверяем корректность интерграции',
    image: optimization,
  },
  {
    title: 'Импортозамещение иностранного ПО на 1С',
    text: 'Оцениваем ваши системы и бизнес-процессы, проектируем функциональную и инфраструктурную миграцию и выполняем комплексный переход',
    image: change,
  },
  {
    title: 'Сопровождение 1С\u2011решений',
    text: 'Обеспечиваем информационную и техническую поддержку цифровых продуктов, чтобы системы компании работали стабильно',
    image: modif,
  },
];

export const devCycleCardsStyles = [
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
  },
  {
    bgColor: 'bg.gray',
  },
  {
    bgColor: 'bg.neonLight',
    templateColumns: { mobile: '1fr', tablet: '1.2fr .8fr', laptop: '1.5fr .5fr' },
  },
  {
    bgColor: 'bg.gray',
    templateColumns: { mobile: '1fr', tablet: '1.2fr .8fr', laptop: '1.5fr .5fr' },
  },
  {
    bgColor: 'text.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
  },
  {
    bgColor: 'bg.black',
    color: 'text.white',
  },
  {
    bgColor: 'bg.neonLight',
    templateColumns: { mobile: '1fr', tablet: '1.2fr .8fr', laptop: '1.5fr .5fr' },
  },
];
