export const layoutConfig1C = {
  0: {
    gridColumn: 'span 3',
    gridRow: 'span 2',
    bgColor: 'gray',
    iconProps: {
      p: 'unset',
      h: '44px',
      boxSize: { mobile: '44px', tablet: '56px', laptop: '72px' },
      imageProps: {
        w: { mobile: '32px', tablet: '37px', laptop: '49px' },
      },
    },
    descriptionProps: {
      p: { mobile: '4px 12px 12px 4px', tablet: '4px 25px 12px 4px', laptop: '8px' },
      textProps: {
        ms: { mobile: '12px', tablet: '20px', laptop: '32px' },
        maxW: 'unset',
      },
    },
  },
  1: {
    gridColumn: 1,
    gridRow: '3 / span 2',
    bgColor: 'gray',
    iconProps: {
      p: 'unset',
      h: '44px',
      boxSize: { mobile: '44px', tablet: '56px', laptop: '72px' },
      imageProps: {
        w: { mobile: '32px', tablet: '40px', laptop: '48px' },
      },
    },
    descriptionProps: {
      p: { mobile: '4px 12px 12px 4px', laptop: '8px' },
      textProps: {
        ms: { mobile: '12px', tablet: '8px', laptop: '32px' },
        maxW: 'unset',
      },
    },
  },
  2: {
    gridColumn: '2 / span 2',
    gridRow: '3',
    bgColor: '#E6E9EE',
    justify: 'end',
    imageProps: {
      objectFit: { mobile: 'contain', tablet: 'cover' },
      boxSize: { mobile: 'auto', tablet: 'full' },
      pb: { mobile: '50px', tablet: '0px' },
    },
    iconProps: {
      p: 'unset',
      h: '44px',
      boxSize: { mobile: '44px', tablet: '56px', laptop: '72px' },
      imageProps: {
        w: { mobile: '32px', tablet: '38px', laptop: '46px' },
      },
    },
    descriptionProps: {
      p: {
        mobile: '4px 12px 12px 4px',
        tablet: '4px 11px 12px 4px',
        laptop: '8px 10px 8px 8px',
        desktop: '8px',
      },
      textProps: {
        ms: { mobile: '12px', tablet: '20px', laptop: '32px' },
        maxW: 'unset',
      },
    },
  },
  3: {
    gridColumn: '2 / span 2',
    gridRow: '4',
    bgColor: '#F5F5E6',
    iconProps: {
      p: 'unset',
      h: '44px',
      boxSize: { mobile: '44px', tablet: '56px', laptop: '72px' },
      imageProps: {
        w: { mobile: '32px', tablet: '37px', laptop: '48px' },
      },
    },
    descriptionProps: {
      p: { mobile: '4px 12px 12px 4px', tablet: '4px 60px 12px 4px', laptop: '8px' },
      textProps: {
        ms: { mobile: '12px', tablet: '20px', laptop: '32px' },
        maxW: { mobile: '195px', tablet: 'unset' },
      },
    },
  },
  4: {
    gridColumn: 'span 3',
    gridRow: 'span 2',
    bgColor: '#F5F5E6',
    iconProps: {
      p: 'unset',
      h: '44px',
      boxSize: { mobile: '44px', tablet: '56px', laptop: '72px' },
      imageProps: {
        w: { mobile: '32px', tablet: '42px', laptop: '48px' },
      },
    },
    descriptionProps: {
      p: { mobile: '4px 12px 12px 4px', laptop: '8px 35px 8px 8px', desktop: '8px' },
      textProps: {
        ms: { mobile: '12px', tablet: '8px', laptop: '32px' },
        maxW: 'unset',
      },
    },
  },
};
