import React, { memo } from 'react';

import { Link as GatsbyLink } from 'gatsby';
import { Button, VStack, Link as ChakraLink } from '@chakra-ui/react';
import { primaryProjects } from 'constants/projects/primary';
import { primaryLayout } from 'constants/projects/projectsLayoutConfigs';
import { ProjectsLayout } from 'shared/ProjectsLayout';
import ProjectCard from '~entities/ProjectCard';
import BlockWrapper from 'shared/BlockWrapper';

const Projects = ({
  HeaderRender = null,
  projectsData = primaryProjects,
  projectsConfig = primaryLayout,
  projectsProps = null,
  onButtonClick = null,
  buttonText = 'Все проекты',
  buttonLink = '/our-projects',
  isButtonVisible = true,
}) => {
  return (
    <VStack spacing={{ laptop: '40px', tablet: '24px', mobile: '20px' }} w="full">
      <BlockWrapper>
        {HeaderRender}
        <ProjectsLayout
          projects={projectsData}
          config={projectsConfig}
          ElementComponent={ProjectCard}
          {...projectsProps}
        />
      </BlockWrapper>
      {isButtonVisible &&
        (onButtonClick ? (
          <Button size="secondary" w="full" onClick={onButtonClick}>
            {buttonText}
          </Button>
        ) : (
          <ChakraLink as={GatsbyLink} to={buttonLink} w="full">
            <Button size="secondary" w="full">
              {buttonText}
            </Button>
          </ChakraLink>
        ))}
    </VStack>
  );
};
export default memo(Projects);
