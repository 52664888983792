import React from 'react';

import BlockHeader from 'shared/BlockHeader';

import { layoutConfig1C } from 'constants/1C/projects/layoutConfigs';
import { allProjects1C } from 'constants/1C/projects/all';

export const projectsData = {
  HeaderRender: <BlockHeader>Кейсы</BlockHeader>,
  projectsData: allProjects1C,
  projectsConfig: layoutConfig1C,
  projectsProps: {
    templateColumns: 'repeat(3,1fr)',
    templateRows: {
      laptop: 'repeat(6,367px)',
      tablet: 'repeat(6,190px)',
    },
  },
  isButtonVisible: true,
  buttonText: 'Скачать прайс-лист',
  buttonLink: 'https://1c.ru/ftp/pub/pricelst/price_1c.zip',
};
