import logo from 'assets/1c/logos/logo_1c.svg';

export const configurationsData = [
  {
    src: logo,
    title: '1C ERP 2.0',
  },
  {
    src: logo,
    title: '1C ЗУП Corp',
  },
  {
    src: logo,
    title: '1C ДО Corp',
  },
  {
    src: logo,
    title: '1C УХ',
  },
];

export const configurationsCardsStyle = {
  boxSize: 'unset',
  w: { mobile: '320px', tablet: 'full' },
  h: { mobile: '180px', laptop: '334px' },
  p: { laptop: '0 36px 40px 36px', mobile: '0 28px 28px 28px' },
  borderRadius: { laptop: '48px', mobile: '24px' },
  titleProps: {
    color: '#23281F',
    textStyle: { mobile: 'header.h4', laptop: 'h4', desktop: 'header.h4' },
  },
  bgColor: 'bg.white',
  border: '1.6px solid',
  borderColor: 'line.gray',
  iconProps: {
    bgColor: 'bg.grayLight',
    p: { laptop: '23px 12px', mobile: '13px 7px' },
    boxSize: { laptop: '72px', mobile: '40px' },
  },
};
